export default defineNuxtRouteMiddleware((to) => {
  const authorized = useCookie('authorized');

  if (!authorized.value) {
    return navigateTo({
      path: '/auth/sign-in',
      query: { authRedirect: to.fullPath },
    });
  }
});
